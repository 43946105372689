module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://599411c9a4964413be4a99853e5c1980@sentry.io/2612221","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thomson Davie Ltd","short_name":"ThomsonDavie","start_url":"/","background_color":"#3f004a","theme_color":"#3f004a","display":"standalone","icon":"./src/assets/img/thomson-davie-stag-white.svg","cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]},"precachePages":["","/about","/contact","/projects","/project/*","/services/*"],"appendScript":"/workspace/web/src/assets/js/sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
